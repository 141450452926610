import { HttpResponse } from '@angular/common/http';
import { LDSingleKindContext } from 'launchdarkly-js-client-sdk';

export type AuthProviderType = 'sealights' | 'cognito' | 'okta' | null;

// If you want some features when disabled to be unavailable
// even for sealights admin user, put those features in this array

export enum UserRole {
	SealightsAdmin = 'sealights-admin',
	UserAdmin = 'user-admin',
	UserDevops = 'user-devops',
	User = 'user',
	UserLimited = 'user-limited'
}

export enum CustomerId {
	SeaLights = 'SeaLights'
}

export enum AuthErrorCode {
	EXPIRED_PASSWORD = 2101
}

export const UserRolePermission = {
	TIA_SELECTION: [UserRole.SealightsAdmin, UserRole.UserDevops],
	CHANGE_TIA_POLICIES: [UserRole.SealightsAdmin, UserRole.UserDevops, UserRole.UserAdmin],
	COCKPIT: [UserRole.SealightsAdmin, UserRole.UserDevops],
	SETTINGS: [UserRole.SealightsAdmin, UserRole.UserDevops, UserRole.UserAdmin],
	CUSTOM_TABS: [UserRole.SealightsAdmin, UserRole.UserDevops, UserRole.UserAdmin]
};

export interface BaseAuthData {
	token?: string;
	challengeName?: string;
	session?: string;
	jwt?: string;
	refreshToken?: string;
	accessToken?: string;
	expires?: number | string;
	expiresTimestamp: string;
	provider?: AuthProviderType; // no provider means "sealights"
	user?: BaseUserData;
	originalUser?: BaseUserData;
	integrations: IntegrationAuthData;
}

export interface IntegrationAuthData {
	ld?: {
		context: LDContext;
		hash: string;
	};
}

export interface LDContext extends LDSingleKindContext {
	customerId?: string;
	email?: string;
	environmentName?: string;
	key?: string;
	kind: string;
	role?: string;
}

export interface SSOConnectionDetails {
	url: string;
	message: string;
}

export interface LoginResult {
	success: boolean;
	userData: BaseAuthData;
	message: HttpResponse<string>;
}

export interface BaseUserData {
	email: string;
	customerId?: string;
	role?: string;
	name?: string;
	family_name?: string;
	userName?: string;
}

export interface CognitoAuthData extends BaseAuthData {
	user: BaseUserData;
}
